@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap");

$primary: #265999;
$primary-dark: #132d4d;
$secondary: #d5ad36;
// $secondary: #DDBD5E;
$light: #ebebeb;

:root {
  height: 100%;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: $light;
  // background-color: $primary;

  height: 100%;

  overflow-x: hidden;

  * {
    box-sizing: border-box;
  }

  .App {
    min-height: 100vh;
    height: 100vh;
  }

  .container {
    // width: 100%;
    margin: 0 auto;
    color: $primary-dark;

    .wrapper {
      padding: 0 48px;

      @media (max-width: "700px") {
        padding: 0 24px;
      }
    }
  }

  header {
    width: 100vw;
    .inner-header {
      padding: 12px 24px;
      position: relative;
      z-index: 10;
      // height: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $primary;
      width: 100vw;

      @media (max-width: "700px") {
        padding: 6px 12px;
      }

      .logo {
        padding: 0 20px;

        @media (max-width: "700px") {
          padding: 0 10px;
        }
      }

      .logo img {
        width: 70px;
        // padding: 50px;

        @media (max-width: "700px") {
          width: 50px;
        }
      }

      .logo a {
        font-weight: 700;
        font-size: 1.5rem;
        text-decoration: none;
        color: $secondary;
      }
      .menu {
        button {
          border: none;
          background: none;
          outline: none;
          cursor: pointer;
          font-size: 1rem;
          // mix-blend-mode: difference;
          font-weight: 600;
          color: $secondary !important;
          font-family: Poppins;

          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .hamburger-menu {
      display: none;
      z-index: 9;
      top: 0;
      left: 0;
      right: 0;
      position: fixed;
      height: 100%;
      width: 100%;
      .menu-secondary-background-color {
        background: $secondary;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: fixed;
        height: 100%;
        width: 100%;
        z-index: -1;
      }
      .menu-layer {
        position: relative;
        background: $primary;
        height: 100%;
        overflow: hidden;
        .menu-city-background {
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          height: 100%;
          width: 100%;
          opacity: 0;
          background-size: 100vh 100vw;
          background-repeat: no-repeat;
          animation-name: backgroundEffect;
          animation-duration: 30s;
          animation-iteration-count: infinite;
        }
        .wrapper {
          position: relative;
          .menu-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            top: 100px;
            nav {
              width: 100%;
              display: flex;
              flex-flow: column;
              height: 50vh;
              div {
                list-style: none;
                font-size: 9vw;
                @media (min-width: "1067px") {
                  font-size: 6rem;
                }
                font-weight: 700;

                height: 10vh;
                // overflow: hidden;
                position: relative;
                width: 100%;
                a {
                  // width: 100%;
                  position: absolute;
                  color: $light;
                  text-decoration: none;
                  &:hover {
                    color: $secondary;
                    cursor: pointer;
                  }
                }
              }
            }
            .info {
              color: $light;
              width: 300px;
              h3 {
                font-size: 1.2rem;
                margin: 8px auto;
              }
              p {
                margin: 0 auto;
                font-size: 0.8rem;
              }
            }
          }
          .locations {
            position: fixed;
            bottom: 80px;
            color: $light;
            // margin-top: 16px;
            font-size: 0.8rem;
            font-weight: 600;

            span {
              padding: 10px;
            }

            .locations-list {
              display: flex;
              flex-flow: row wrap;

              span {
                cursor: pointer;
                &:first-child {
                }
                font-weight: 400;
                // margin: 0 32px;
                margin-right: 20px;
                padding: 10px 20px;
                transition: 0.3s ease-in-out;
                &:hover {
                  color: $light;
                  background: $secondary;
                  // padding: 8px 24px;
                  border-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
  }

  .app-body {
    // height: 100%;
    width: 100vw;

    padding: 24px 24px;
    @media (max-width: "700px") {
      padding: 12px 12px;
    }
  }

  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    p {
      font-size: 0.75rem;
      letter-spacing: 0.3rem;
      text-transform: uppercase;
      font-weight: 500;
    }
    h5 {
      margin-top: 260px;
      font-size: 24px;
      font-weight: 600;
      padding-right: 360px;
    }
  }

  .menu-page {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    .upcharge {
      min-width: 50px;
      margin-left: 12px;
      display: flex;
      flex-flow: row nowrap;
      font-weight: 300;
      margin-top: 1px;
      background-color: $primary;
      padding: 6px;

      svg {
        width: 14px;
        height: auto;
        // padding-bottom: 2px;
        color: green;
      }
    }

    .uk-tab .uk-active a {
      border-color: $primary;
      color: $primary;
    }

    .menu-container {
      max-width: 700px;
      width: 100%;
    }

    .menu-tab-link {
      font-weight: 700;
    }

    .menu-tabs {
      width: 100%;
    }

    .pdf {
      height: calc(75vh);
    }

    .menu-description {
      font-size: 0.8rem;
      border-radius: 8px;

      .upcharge-description {
        align-items: center;
        font-size: 0.7rem;
        font-style: italic;
      }
    }

    .uk-accordion-content {
      margin: 0;
    }

    .menu-item {
      margin: 10px 0;

      dl {
        margin: 0;
      }

      div:first-child {
        margin-right: 10px;

        width: 100px !important;
        flex: 0 0 100px;

        border: 1px solid rbga(0, 0, 0, 1) !important;

        img {
          -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);

          width: 100px !important;
          height: 100px !important;
          object-fit: cover;
        }
      }
    }

    .bold {
      font-weight: 600;
    }
  }

  .test {
    border: 1px solid red;
  }

  .clickable {
    cursor: pointer;
  }

  @keyframes backgroundEffect {
    0% {
      background-position: 0% 0%;
    }
    25% {
      background-position: 40% 10%;
    }
    50% {
      background-position: 0 10%;
    }
    75% {
      background-position: 10% 40%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
}
