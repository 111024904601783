@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,600,800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #ebebeb;
  height: 100%;
  overflow-x: hidden;
}
html *,
body * {
  box-sizing: border-box;
}
html .App,
body .App {
  min-height: 100vh;
  height: 100vh;
}
html .container,
body .container {
  margin: 0 auto;
  color: #132d4d;
}
html .container .wrapper,
body .container .wrapper {
  padding: 0 48px;
}
@media (max-width: 700px) {
  html .container .wrapper,
body .container .wrapper {
    padding: 0 24px;
  }
}
html header,
body header {
  width: 100vw;
}
html header .inner-header,
body header .inner-header {
  padding: 12px 24px;
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #265999;
  width: 100vw;
}
@media (max-width: 700px) {
  html header .inner-header,
body header .inner-header {
    padding: 6px 12px;
  }
}
html header .inner-header .logo,
body header .inner-header .logo {
  padding: 0 20px;
}
@media (max-width: 700px) {
  html header .inner-header .logo,
body header .inner-header .logo {
    padding: 0 10px;
  }
}
html header .inner-header .logo img,
body header .inner-header .logo img {
  width: 70px;
}
@media (max-width: 700px) {
  html header .inner-header .logo img,
body header .inner-header .logo img {
    width: 50px;
  }
}
html header .inner-header .logo a,
body header .inner-header .logo a {
  font-weight: 700;
  font-size: 1.5rem;
  text-decoration: none;
  color: #d5ad36;
}
html header .inner-header .menu button,
body header .inner-header .menu button {
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  color: #d5ad36 !important;
  font-family: Poppins;
  display: flex;
  justify-content: center;
  align-items: center;
}
html header .inner-header .menu button svg,
body header .inner-header .menu button svg {
  width: 24px;
  height: 24px;
}
html header .hamburger-menu,
body header .hamburger-menu {
  display: none;
  z-index: 9;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  height: 100%;
  width: 100%;
}
html header .hamburger-menu .menu-secondary-background-color,
body header .hamburger-menu .menu-secondary-background-color {
  background: #d5ad36;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -1;
}
html header .hamburger-menu .menu-layer,
body header .hamburger-menu .menu-layer {
  position: relative;
  background: #265999;
  height: 100%;
  overflow: hidden;
}
html header .hamburger-menu .menu-layer .menu-city-background,
body header .hamburger-menu .menu-layer .menu-city-background {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-size: 100vh 100vw;
  background-repeat: no-repeat;
  -webkit-animation-name: backgroundEffect;
          animation-name: backgroundEffect;
  -webkit-animation-duration: 30s;
          animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
html header .hamburger-menu .menu-layer .wrapper,
body header .hamburger-menu .menu-layer .wrapper {
  position: relative;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links,
body header .hamburger-menu .menu-layer .wrapper .menu-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 100px;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links nav,
body header .hamburger-menu .menu-layer .wrapper .menu-links nav {
  width: 100%;
  display: flex;
  flex-flow: column;
  height: 50vh;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links nav div,
body header .hamburger-menu .menu-layer .wrapper .menu-links nav div {
  list-style: none;
  font-size: 9vw;
  font-weight: 700;
  height: 10vh;
  position: relative;
  width: 100%;
}
@media (min-width: 1067px) {
  html header .hamburger-menu .menu-layer .wrapper .menu-links nav div,
body header .hamburger-menu .menu-layer .wrapper .menu-links nav div {
    font-size: 6rem;
  }
}
html header .hamburger-menu .menu-layer .wrapper .menu-links nav div a,
body header .hamburger-menu .menu-layer .wrapper .menu-links nav div a {
  position: absolute;
  color: #ebebeb;
  text-decoration: none;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links nav div a:hover,
body header .hamburger-menu .menu-layer .wrapper .menu-links nav div a:hover {
  color: #d5ad36;
  cursor: pointer;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links .info,
body header .hamburger-menu .menu-layer .wrapper .menu-links .info {
  color: #ebebeb;
  width: 300px;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links .info h3,
body header .hamburger-menu .menu-layer .wrapper .menu-links .info h3 {
  font-size: 1.2rem;
  margin: 8px auto;
}
html header .hamburger-menu .menu-layer .wrapper .menu-links .info p,
body header .hamburger-menu .menu-layer .wrapper .menu-links .info p {
  margin: 0 auto;
  font-size: 0.8rem;
}
html header .hamburger-menu .menu-layer .wrapper .locations,
body header .hamburger-menu .menu-layer .wrapper .locations {
  position: fixed;
  bottom: 80px;
  color: #ebebeb;
  font-size: 0.8rem;
  font-weight: 600;
}
html header .hamburger-menu .menu-layer .wrapper .locations span,
body header .hamburger-menu .menu-layer .wrapper .locations span {
  padding: 10px;
}
html header .hamburger-menu .menu-layer .wrapper .locations .locations-list,
body header .hamburger-menu .menu-layer .wrapper .locations .locations-list {
  display: flex;
  flex-flow: row wrap;
}
html header .hamburger-menu .menu-layer .wrapper .locations .locations-list span,
body header .hamburger-menu .menu-layer .wrapper .locations .locations-list span {
  cursor: pointer;
  font-weight: 400;
  margin-right: 20px;
  padding: 10px 20px;
  transition: 0.3s ease-in-out;
}
html header .hamburger-menu .menu-layer .wrapper .locations .locations-list span:hover,
body header .hamburger-menu .menu-layer .wrapper .locations .locations-list span:hover {
  color: #ebebeb;
  background: #d5ad36;
  border-radius: 4px;
}
html .app-body,
body .app-body {
  width: 100vw;
  padding: 24px 24px;
}
@media (max-width: 700px) {
  html .app-body,
body .app-body {
    padding: 12px 12px;
  }
}
html .home,
body .home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
html .home p,
body .home p {
  font-size: 0.75rem;
  letter-spacing: 0.3rem;
  text-transform: uppercase;
  font-weight: 500;
}
html .home h5,
body .home h5 {
  margin-top: 260px;
  font-size: 24px;
  font-weight: 600;
  padding-right: 360px;
}
html .menu-page,
body .menu-page {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
html .menu-page .upcharge,
body .menu-page .upcharge {
  min-width: 50px;
  margin-left: 12px;
  display: flex;
  flex-flow: row nowrap;
  font-weight: 300;
  margin-top: 1px;
  background-color: #265999;
  padding: 6px;
}
html .menu-page .upcharge svg,
body .menu-page .upcharge svg {
  width: 14px;
  height: auto;
  color: green;
}
html .menu-page .uk-tab .uk-active a,
body .menu-page .uk-tab .uk-active a {
  border-color: #265999;
  color: #265999;
}
html .menu-page .menu-container,
body .menu-page .menu-container {
  max-width: 700px;
  width: 100%;
}
html .menu-page .menu-tab-link,
body .menu-page .menu-tab-link {
  font-weight: 700;
}
html .menu-page .menu-tabs,
body .menu-page .menu-tabs {
  width: 100%;
}
html .menu-page .pdf,
body .menu-page .pdf {
  height: 75vh;
}
html .menu-page .menu-description,
body .menu-page .menu-description {
  font-size: 0.8rem;
  border-radius: 8px;
}
html .menu-page .menu-description .upcharge-description,
body .menu-page .menu-description .upcharge-description {
  align-items: center;
  font-size: 0.7rem;
  font-style: italic;
}
html .menu-page .uk-accordion-content,
body .menu-page .uk-accordion-content {
  margin: 0;
}
html .menu-page .menu-item,
body .menu-page .menu-item {
  margin: 10px 0;
}
html .menu-page .menu-item dl,
body .menu-page .menu-item dl {
  margin: 0;
}
html .menu-page .menu-item div:first-child,
body .menu-page .menu-item div:first-child {
  margin-right: 10px;
  width: 100px !important;
  flex: 0 0 100px;
  border: 1px solid rbga(0, 0, 0, 1) !important;
}
html .menu-page .menu-item div:first-child img,
body .menu-page .menu-item div:first-child img {
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.2);
  width: 100px !important;
  height: 100px !important;
  object-fit: cover;
}
html .menu-page .bold,
body .menu-page .bold {
  font-weight: 600;
}
html .test,
body .test {
  border: 1px solid red;
}
html .clickable,
body .clickable {
  cursor: pointer;
}
@-webkit-keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}
@keyframes backgroundEffect {
  0% {
    background-position: 0% 0%;
  }
  25% {
    background-position: 40% 10%;
  }
  50% {
    background-position: 0 10%;
  }
  75% {
    background-position: 10% 40%;
  }
  100% {
    background-position: 0% 0%;
  }
}
